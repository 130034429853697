<template>
    <v-card flat width="100%">

        <v-expansion-panels v-if="!showMainProgress">

            <div style="width:100%" v-if="items.past.length > 0">

                <v-expansion-panel
                        :key="i"
                        v-for="(item,i) in items.past"
                >
                    <!--                                    <TestsDisplayExpansionItem :item="item"/>-->

                    <v-expansion-panel-header class="pa-0 hidden-sm-and-up">
                        <v-row
                                dense>
                            <v-col
                                    cols="2"
                                    md="1"
                                    sm="2"
                                    xs="3"

                            >
                                <DateAndMonthView :item="item" class="ma-1"/>

                            </v-col>
                            <v-col
                                    cols="9"
                                    md="10"
                                    sm="8"
                            >

                                <p class="pt-2 title mb-2">{{item.title}}</p>
                                <span class="pb-1">{{item.sub}}</span>
                                <p class="pt-1 pb-2">{{item.subTime}}</p>
                            </v-col>
                            <v-col
                                    cols="2"
                                    md="1"
                                    sm="2"
                            >
                                <v-btn class="ml-2" color="green" outlined v-if="parseInt(item.attempted) === 0">
                                    ATTEMPT
                                </v-btn>
                                <v-btn @click="showResult(item)" color="indigo" dark v-else>Show Result</v-btn>

                            </v-col>
                        </v-row>

                    </v-expansion-panel-header>
                    <v-expansion-panel-header class="pa-4 hidden-xs-only">

                        <v-col
                                cols="2"
                                md="1"
                                sm="2"
                        >
                            <v-card class="text-center" color="indigo" dark>
                                <p class="pt-2 title  mb-2">{{item.date}}</p>
                                <v-divider class="pb-2"/>
                                <p class="text-center pb-2">{{item.month}}</p>
                            </v-card>

                        </v-col>
                        <v-col
                                cols="9"
                                md="10"
                                sm="8"
                        >

                            <p class="pt-2 title mb-2">{{item.title}}</p>
                            <span class="pb-1">{{item.sub}}</span>
                            <p class="pt-1 pb-2">{{item.subTime}}</p>
                        </v-col>
                        <v-col
                                cols="2"
                                md="2"
                                sm="2"
                        >
                            <v-btn color="green" outlined v-if="parseInt(item.attempted) === 0">ATTEMPT</v-btn>
                            <v-btn @click="showResult(item)" color="indigo" dark
                                   v-else-if="parseInt(item.attempted) === 1">Show Result
                            </v-btn>

                        </v-col>

                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-flex sm6 xs12>
                                <p>
                                    <v-icon left>mdi-information-outline</v-icon>
                                    Number of Questions: {{item.noOfQ}}
                                </p>

                            </v-flex>
                            <v-flex sm6 xs12>
                                <p>
                                    <v-icon left>mdi-clock</v-icon>
                                    Time : {{item.time}} min
                                </p>
                            </v-flex>

                            <v-flex sm6 xs12>
                                <p>
                                    <v-icon left>mdi-check</v-icon>
                                    Correct Marks: +4
                                </p>
                            </v-flex>

                            <v-flex sm6 xs12>
                                <p>
                                    <v-icon left>mdi-close</v-icon>
                                    Wrong Marks: -1
                                </p>
                            </v-flex>

                            <v-flex sm6 xs12>
                                <p>
                                    <v-icon left>mdi-checkbox-blank-circle-outline</v-icon>
                                    Unattempted Marks: 0
                                </p>
                            </v-flex>

                        </v-row>

                        <v-row>
                            <v-flex class="text-center" xs12>
                                <v-btn @click="showResult(item)" block color="indigo" dark
                                       v-if="parseInt(item.attempted) === 1">
                                    <v-icon left>mdi-chart-bar</v-icon>
                                    Show Result
                                </v-btn>
                                <v-btn @click="startPrevTest(item)" block color="indigo" dark
                                       v-else-if="parseInt(item.attempted) === 0 ">
                                    <v-icon left>mdi-send-circle-outline</v-icon>
                                    Start Test
                                </v-btn>


                            </v-flex>
                        </v-row>

                    </v-expansion-panel-content>
                </v-expansion-panel>
            </div>
            <div class="text-center ma-5" v-else>No tests found. Please try again in a while.</div>


            <v-progress-circular
                    :size="50"
                    :width="7"
                    class="ma-2 pa-2"
                    color="purple"
                    indeterminate
                    v-if="showProgress"
            />
        </v-expansion-panels>
        <div class="text-center ma-5" v-else>
            <v-progress-circular
                    :size="50"
                    :width="7"
                    class="ma-2 pa-2"
                    color="purple"
                    indeterminate


            />
        </div>

        <v-dialog
                max-width="290"
                v-model="dialogs"
        >
            <v-card>
                <v-card-title class="headline">
                    <v-icon left>mdi-alert</v-icon>
                    {{dialogText.title}}
                </v-card-title>

                <v-card-text>
                    {{dialogText.body}}
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn @click="dialogs = false" color="green darken-1 white--text">close
                    </v-btn>
                    <v-btn
                            @click="$router.push({'name':'subscribe'})"
                            color="indigo darken-1 white--text"

                    >Subscribe
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-card>
</template>
<script>
    import DateAndMonthView from "./DateAndMonthView";

    export default {
        name: 'PreviousTests',
        components: {DateAndMonthView},
        data() {
            return {
                dialogs: false,
                dialogText: {},
                isItDailyTest: false,
            }
        }, computed: {
            showMainProgress() {
                return this.$store.getters.showProgress;
            },
        },
        props: {
            items: {},
            showProgress: {},
            subscribed: {}
        },
        mounted() {
            this.$nextTick(function () {

                let path = this.$router.currentRoute.name;
                // eslint-disable-next-line no-console
                // console.log(path);
                this.isItDailyTest = path.includes("daily-tests");
            });

        },
        methods: {
            startPrevTest(item) {
                if (this.subscribed) {

                    if (item.validSubscription) {
                        if (this.isItDailyTest) {
                            // eslint-disable-next-line no-console
                            // console.log("Starting test with ID: " + item.id);
                            this.$router.push({name: "startDailyTest", params: {id: item.id}});
                        } else {

                            this.$router.push({name: "startWeeklyTest", params: {id: item.id}});

                        }
                    } else {
                        let validFrom = this.$store.getters.getSubscriptionValidFrom;
                        let validTill = this.$store.getters.getSubscriptionValidTill;
                        this.dialogText = {
                            body: "This test is out of your subscription range.\n" +
                                " Your subscription range is from " + validFrom + " till " + validTill +
                                "\n Upgrade your subscription which allows access to more number of previous tests."
                            ,
                            title: "Oops..."
                        };

                        this.dialogs = true;
                    }


                } else {
                    // eslint-disable-next-line no-console
                    // console.log("Can't start the test as you are not subscribed");
                    this.dialogText = {
                        body: "Previous tests are only available to subscribed users. To know more click Subscribe.",
                        title: "Hmmm..."
                    };

                    this.dialogs = true;
                    // this.$store.commit("settestsErrorDialog", true);

                }
            },
            showResult(item) {
                if (this.isItDailyTest) {
                    // eslint-disable-next-line no-console
                    // console.log("Showing result with ID: " + item.id);
                    this.$router.push({name: "solutionDaily", params: {id: item.id}});
                } else {

                    this.$router.push({name: "solutionWeekly", params: {id: item.id}});

                }


            }
        }
    }
</script>