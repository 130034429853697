<template>
    <v-card class="text-center mt-3" color="indigo" dark>
        <p class="pt-2 title  mb-2">{{item.date}}</p>
        <v-divider class="pb-2"/>
        <p class="text-center pb-2">{{item.month}}</p>
    </v-card>
</template>
<script>
    export default {
        name: 'DateAndMonthView',
        props: {
            item: {}
        }
    }
</script>