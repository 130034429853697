<template v-cloak>
    <v-card flat width="100%">
        <v-expansion-panels v-if="!showProgress">
            <div style="width:100%" v-if="items.upcoming.length > 0">
                <v-expansion-panel
                        :key="i"
                        v-for="(item,i) in items.upcoming"
                >
                    <!--                                    <TestsDisplayExpansionItem :item="item"/>-->
                    <v-expansion-panel-header class="pa-0 hidden-sm-and-up">
                        <v-row
                                dense>


                            <v-col
                                    cols="2"
                                    md="1"
                                    sm="2"
                                    xs="3"
                            >
                                <DateAndMonthView :item="item" class="ma-1"/>


                            </v-col>
                            <v-col
                                    cols="9"
                                    md="10"
                                    sm="8"
                                    xs="8"
                            >

                                <p class="pt-2 title mb-2">{{item.title}}</p>
                                <span class="pb-1">{{item.sub}}</span>
                                <p class="pt-1 pb-2">{{item.subTime}}</p>
                            </v-col>
                            <v-col
                                    cols="2"
                                    md="1"
                                    sm="2"
                                    xs="2"
                            >

                                <v-btn class="ml-2" color="green" outlined v-if="item.live">LIVE</v-btn>

                            </v-col>
                        </v-row>

                    </v-expansion-panel-header>
                    <v-expansion-panel-header class="pa-4 hidden-xs-only">
                        <v-col
                                cols="2"
                                md="1"
                                sm="2"
                        >
                            <v-card class="text-center" color="indigo" dark>
                                <p class="pt-2 title  mb-2">{{item.date}}</p>
                                <v-divider class="pb-2"/>
                                <p class="text-center pb-2">{{item.month}}</p>
                            </v-card>

                        </v-col>
                        <v-col
                                cols="9"
                                md="10"
                                sm="8"
                        >

                            <p class="pt-2 title mb-2">{{item.title}}</p>
                            <span class="pb-1">{{item.sub}}</span>
                            <p class="pt-1 pb-2">{{item.subTime}}</p>
                        </v-col>
                        <v-col
                                class="text-center"
                                cols="2"
                                md="1"
                                sm="2"
                        >
                            <v-btn color="green" outlined v-if="item.live">LIVE</v-btn>
                        </v-col>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-flex sm6 xs12>
                                <p>
                                    <v-icon left>mdi-information-outline</v-icon>
                                    Number of Questions: {{item.noOfQ}}
                                </p>

                            </v-flex>
                            <v-flex sm6 xs12>
                                <p>
                                    <v-icon left>mdi-clock</v-icon>
                                    Time : {{item.time}} min
                                </p>
                            </v-flex>

                            <v-flex sm6 xs12>
                                <p>
                                    <v-icon left>mdi-check</v-icon>
                                    Correct Marks: +4
                                </p>
                            </v-flex>

                            <v-flex sm6 xs12>
                                <p>
                                    <v-icon left>mdi-close</v-icon>
                                    Wrong Marks: -1
                                </p>
                            </v-flex>

                            <v-flex sm6 xs12>
                                <p>
                                    <v-icon left>mdi-checkbox-blank-circle-outline</v-icon>
                                    Unattempted Marks: 0
                                </p>
                            </v-flex>

                        </v-row>
                        <v-row>
                            <v-flex class="text-center" xs12>
                                <v-btn @click="startTests(item)" block color="grey" dark v-if="!item.live">
                                    <v-icon left>mdi-information-outline</v-icon>
                                    Yet to Start
                                </v-btn>
                                <v-btn @click="startTests(item)" block color="indigo" dark
                                       v-else-if="parseInt(item.attempted) === 0">
                                    <v-icon left>mdi-send-circle-outline</v-icon>
                                    Start Test
                                </v-btn>

                                <v-btn @click="showResult(item)" block color="indigo" dark
                                       v-else-if="parseInt(item.attempted) === 1">
                                    <v-icon left>mdi-chart-bar</v-icon>
                                    Show Result
                                </v-btn>

                            </v-flex>
                        </v-row>


                    </v-expansion-panel-content>
                </v-expansion-panel>
            </div>
            <div class="text-center ma-5" v-else>No tests found. Please try again in a while.</div>
        </v-expansion-panels>
        <div class="text-center ma-5" v-else>
            <v-progress-circular
                    :size="50"
                    :width="7"
                    class="ma-2 pa-2"
                    color="purple"
                    indeterminate


            />
        </div>
        <ErrorDialogTests :dialog="dialog" :dialog-text="dialogText" :showwhat="1"/>
    </v-card>

</template>
<script>
    import DateAndMonthView from "./DateAndMonthView";
    import ErrorDialogTests from "./ErrorDialogTests";

    export default {
        name: 'LiveUpcomingTests',
        components: {ErrorDialogTests, DateAndMonthView},
        data() {
            return {
                dialog: false,
                dialogText: {},

            }
        },
        computed: {
            showProgress() {
                return this.$store.getters.showProgress;
            },
        },
        props: {
            items: {},
            subscribed: {}

        },
        mounted() {
            this.$nextTick(function () {

                let path = this.$router.currentRoute.name;
                // eslint-disable-next-line no-console
                // console.log(path);
                this.isItDailyTest = path.includes("daily-tests");
            });

        },
        methods: {
            // getBody() {
            //
            //     // eslint-disable-next-line no-console
            //     console.log("D " + this.date);
            //     let string =;
            //     return string;
            // },
            startTests(item) {
                // eslint-disable-next-line no-console
                // console.log("data is: " + this.subscribed);
                if (item.live === true) {

                    if (this.isItDailyTest) {
                        // eslint-disable-next-line no-console
                        console.log("Starting test with ID: " + item.id);
                        this.$router.push({name: "startDailyTest", params: {id: item.id}});
                    } else {

                        this.$router.push({name: "startWeeklyTest", params: {id: item.id}});

                    }


                } else {
                    this.dialogText = {
                        body: "Test will start on " +
                            item.date + " " + item.month + ". Keep preparing till then.",
                        title: "😞 Ooops.."
                    };
                    // eslint-disable-next-line no-console
                    // console.log(this.date);

                    this.dialog = true;
                    this.$store.commit("settestsErrorDialog", true);
                    // eslint-disable-next-line no-console
                    // console.log("Test has not started yet: " + item.id);


                }

            },
            showResult(item) {
                if (this.isItDailyTest) {
                    // eslint-disable-next-line no-console
                    // console.log("Showing result with ID: " + item.id);
                    this.$router.push({name: "solutionDaily", params: {id: item.id}});
                } else {


                    this.$router.push({name: "solutionWeekly", params: {id: item.id}});

                }
            }
        }
    }
</script>
<style scoped>
    [v-cloak] {
        display: none;
    }
</style>