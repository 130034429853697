<template>
    <v-dialog
            max-width="290"
            v-model="dialogs"
    >
        <v-card>
            <v-card-title class="headline">
                <v-icon left>mdi-alert</v-icon>
                {{dialogText.title}}
            </v-card-title>

            <v-card-text>
                {{dialogText.body}}
            </v-card-text>

            <v-card-actions>
                <v-spacer/>
                <v-btn
                        @click="closeDialog"
                        color="green darken-1"
                        text
                >
                    Close
                </v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: 'ErrorDialogTests',
        props: {
            // dialog:{},
            dialogText: {}
        },
        computed: {
            dialogs: {
                get() {
                    return this.$store.getters.getErrorDialogTest;
                },

                set(value) {
                    this.$store.commit("settestsErrorDialog", value);
                },

            }
        },

        methods: {
            closeDialog() {
                this.$store.commit("settestsErrorDialog", false);

            }
        }
    }
</script>